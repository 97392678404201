import React from "react";
import { connect } from "react-redux";
import { toggleMenuDisplay, setFilters, setClients } from "../../actions/AppActions";
import i18n from "i18next";
import FedExLogo from "../../Images/fedex-logo.png";

class ClientFilter extends React.Component {
    constructor(props, context) {
        super(props, context);
    this.state = {
  

        clientOptions:[],
      searchedClientText:''
    };
}
    componentDidMount() {
     

        if(this.props.app.clientOptions.length!=this.state.clientOptions.length){
            this.setState({
              clientOptions:this.props.app.clientOptions,
              searchedClientText:''
            })
          }

    }
    componentWillReceiveProps(nextProps) {
    
        if(nextProps.app.clientOptions.length!=this.state.clientOptions.length){
          this.setState({
            clientOptions:nextProps.app.clientOptions,
            searchedClientText:''
          })
        }
      }
      onChangeClient=(e)=>{
        let searchedText=e.target.value.toLowerCase().trim()
          this.setState({
            clientOptions:this.props.app.clientOptions.filter(x=>x.tenantName.toLowerCase().includes(searchedText)),
            searchedClientText:e.target.value
          })
    
      }
      showImage = (imageDetails) => {
        return imageDetails.iconName != null && imageDetails.iconName != '' && imageDetails.appIcon != null && imageDetails.appIcon != ''
      }
      getImageSrc = (client) => {
        const appIcon = client.appIcon
        const iconName = client.iconName
    
        if (iconName != null) {
          var n = iconName.lastIndexOf('.');
          var extn = iconName.substring(n + 1);
          var imageSrc = extn == 'svg' ? "data:image/svg+xml;base64, " + appIcon : "data:image/png;base64, " + appIcon
          return imageSrc
        }
        else {
          return 'data:image/jpeg;base64, ' + appIcon
        }
      }
 
    
    render() {


        //Construct dropdown options for filters
     
        return (
            <div className="selectClientHolderDiv" hidden={this.props.selectClientHolderDiv} data-test='client-filter'>
            <div class="row mt-2 mb-2">
            <div class="col-8" ><h5>{i18n.t('Select Client')}</h5></div>
            {/* <div class="col-4 text-right"><a className="clearSearchCTA" style={{ cursor: 'pointer' }} hidden={this.props.userDetails.tenant.length==1?true:false} onClick={() => this.props.onSelectClient({})}>{i18n.t('Clear')}</a></div> */}
            </div>
                      <div className='input-search-client'>
            <input className="HMCCtxtFld" placeholder={i18n.t('Search') +'..'} type='text' onChange={this.onChangeClient} value={this.state.searchedClientText}></input>
            </div>
            <div className='ClientListInner' >


              {this.state.clientOptions.map((client, index) => {
                return (
                  <a className="media px-3 py-2" key={index} onClick={() => this.props.onSelectClient(client)}>
                    {/* <img src={
                      this.showImage(client) ? this.getImageSrc(client) : require("../../Images/hmcc-default-client.svg")} className="mr-2 rounded-circle" /> */}
                    <img src={FedExLogo} className="mr-2 rounded-circle" />
                    <div className="media-body">
                      {/* {client.tenantName} */}
                      FedEx
                    </div>
                  </a>

                )
              })}
            </div>
          </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
      app: state.appReducer,
  
    };
  };
const mapDispatchToProps = (dispatch) => {
    return {
      toggleMenuDisplay: (boolean) => {
        dispatch(toggleMenuDisplay(boolean));
      }
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(ClientFilter);

