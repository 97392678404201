import React from "react";
import { connect } from "react-redux";
import { toggleMenuDisplay, setFilters } from "../../actions/AppActions";
import { Row, Col, Modal } from "react-bootstrap";
import { ModalImg } from "../../config/imgConfig";
import { ENVIRONMENT, ADMIN } from "../../StringConstants";
import i18n from "i18next";

let dashFlow =  [
  {dash:"5f9ae0175fd2f53f38606dda", 
  wgt:[
      {id:"5f9ae0175fd2f53f38606dde",ref:false},
      {id:"5f9ae0175fd2f53f38606ddb",ref:false}, 
      {id:"5f9ae0175fd2f53f38606ddc",ref:false},
      {id:"5f9ae0175fd2f53f38606ddd",ref:false},

      {id:"5f9ae0175fd2f53f38606de2",ref:false},
      {id:"5f9ae0175fd2f53f38606ddf",ref:false}, 
      {id:"5f9ae0175fd2f53f38606de0",ref:false},
      {id:"5f9ae0175fd2f53f38606de1",ref:false},

      {id:"5f9ae0175fd2f53f38606de7",ref:false},
      {id:"5f9ae0175fd2f53f38606de3",ref:false},
      {id:"5f9ae0175fd2f53f38606de5",ref:false},
      {id:"5f9ae0175fd2f53f38606de6",ref:false},
  ]},
  {dash:"5f719a0e3dad070ee862ce50", 
  wgt:[
      {id:"5f719a0e3dad070ee862ce53",ref:false},
      {id:"5f719a0e3dad070ee862ce52",ref:false},
      {id:"5f719a0e3dad070ee862ce51",ref:false},
  ]},
  {
    dash: "672b2317d838de003388cab0",
    wgt: [
      { id: "672b2317d838de003388cab2", ref: true },
      { id: "672b2317d838de003388cab4", ref: true },
      { id: "672b2317d838de003388cab1", ref: true },
      { id: "672b2317d838de003388cab3", ref: true },
    ]
  },
  {
    dash: "672b231397a46a003344f7a7",
    wgt: [
      { id: "672b231397a46a003344f7b1", ref: true },
      { id: "672b231397a46a003344f7af", ref: true },
      { id: "672b231397a46a003344f7a9", ref: true },
      { id: "672b231397a46a003344f7b2", ref: true },
      { id: "672b231397a46a003344f7a8", ref: true },
      { id: "672b231397a46a003344f7aa", ref: true },
      { id: "672b231397a46a003344f7b0", ref: true },
      { id: "672b231397a46a003344f7ac", ref: true },
      { id: "672b231397a46a003344f7ad", ref: true },
      { id: "672b231397a46a003344f7ae", ref: true },
      { id: "672b231397a46a003344f7ab", ref: false },
    ]
  },
];
class B9DataSection extends React.Component {
  state={
    filterArrayBO:[],
    sisenseFrames:[],
    isRendered:false,
    applyFiltersLater:false,
    showLoader: false,
    preWgt:[],
    loaded:false,
    show:false,
    arrowShow:false,
    active:0,
    image:'',
    isFilter:false,
    loading:false,
    //operations
    filterArrayOP:[],
    filterPortfolio:{},
    showModal:false,
    showModal2:false,
    showDashModal:false,
    disableClick:false
  }
    
  applyDbFilterBO =(db)=>{
    const payload =  [...this.state.filterArrayBO];
    if(payload){if(!payload[0].levels[1].filter.all) this.setState({isFilter:true});}
    const filterOptions = {
        save: true,
        refresh: true,
        unionIfSameDimensionAndSameType: false
    };
    if(db.id !== '5f719a0e3dad070ee862ce50')
    db.$$model.filters.update(payload[0], filterOptions)
}
  
  componentWillMount(){
    let clients;
    if(this.props.app.filterObject.tenant.length>0){
      // clients = [...this.props.app.filterObject.tenant];
      clients = [...this.props.app.clients];
    }else{
      let tenants = [];
        this.props.userDetails.tenant.map((item) => {
          // tenants.push(item.pseudoName);
          tenants.push(item.tenantName);
        });
      clients = [...tenants];
    }
    let clientsFilterObj;
    if(clients.length>0){
      clientsFilterObj = {
        explicit: true,
        multiSelection: true,
        members: clients.map((a) => a.toUpperCase()),
      }
    }else{
      clientsFilterObj ={
        all: true,
      }
    }
    this.setState({
      filterArrayBO: [
        {
         
          levels: [
            {
              dim: "[Client.Type]",
              datatype: "text",
              title: "Type",
              filter: {
                explicit: true,
                userMultiSelect: false,
                multiSelection: false,
                members: ["Demo"],
              },
            },
            {
              dim: "[Client.Client]",
              datatype: "text",
              title: "Client",
              filter: clientsFilterObj,
            },
          ],
        },
      ],
      filterArrayOP: [
        {
         
          levels: [
            {
              dim: "[Ticket.Type]",
              datatype: "text",
              title: "Type",
              filter: {
                explicit: true,
                userMultiSelect: false,
                multiSelection: false,
                members: ["Demo"],
              },
            },
            {
              dim: "[Ticket.Client]",
              datatype: "text",
              title: "Client",
              filter: clientsFilterObj,
            },
          ],
        },
      ],
    });
  }

  componentDidMount() {
    let dashBoard1= this.props.config.find(element => element.position == 1);//Announcements
    let dashBoard2= this.props.config.find(element => element.position == 2);//Open tickets
    let dashBoard3= this.props.config.find(element => element.position == 3);//OPERATIONS DASHBOARD
    
    if(ENVIRONMENT!=='local'){
    if(!this.state.loaded) this.startFlow();  //Uncomment this before building - LOCAL
    // this.loadDash('opDashboard1', dashBoard1.url); 
    // this.loadDash('opDashboard2', dashBoard2.url);
    // this.loadDash('opDashboard3', dashBoard3.url);
    }
  }
  componentWillReceiveProps(nextProps){
    if(this.props.app.clients !== nextProps.app.clients){
      let filterArrayBO = [];
      let filterArrayOP = [];
      if(nextProps.app.filterObject.tenant.length == 0 && this.props.userDetails.tenant.length == 0){
        filterArrayBO = [
          {
            "levels": [
              {
                "dim": "[Client.Type]",
                "datatype": "text",
                "title": "Type",
                "filter": {
                  "explicit": true,
                  "userMultiSelect": false,
                  "multiSelection": false,
                  "members": ["Demo"],
                },
              },
              {
                "title" : "Client",
                "dim" : "[Client.Client]",
                "datatype" : "text",
                "filter" : {
                "all":true
              }
            }
            ],
          }
        ]
        filterArrayOP = [
          {
            "levels": [
              {
                "dim": "[Ticket.Type]",
                "datatype": "text",
                "title": "Type",
                "filter": {
                  "explicit": true,
                  "userMultiSelect": false,
                  "multiSelection": false,
                  "members": ["Demo"],
                },
              },
              {
                "title" : "Client",
                "dim" : "[Ticket.Client]",
                "datatype" : "text",
                "filter" : {
                "all":true
              }
            }
            ],
          }
        ]

    }else if(nextProps.app.filterObject.tenant.length == 0 && this.props.userDetails.tenant.length != 0){

      let tenants = [];
           this.props.userDetails.tenant.map((item) => {
                // tenants.push( item.pseudoName);
                tenants.push(item.tenantName);
            });
                filterArrayBO = [
                    {
                    "levels": [
                      {
                        "dim": "[Client.Type]",
                        "datatype": "text",
                        "title": "Type",
                        "filter": {
                          "explicit": true,
                          "userMultiSelect": false,
                          "multiSelection": false,
                          "members": ["Demo"],
                        },
                      },
                      {
                        "dim": "[Client.Client]",
                        "datatype": "text",
                        "title": "Client",
                        "filter": {"explicit": true, "multiSelection": true, "members": tenants.map(a => a.toUpperCase())},
                      },
                    ],
                  }
                ]
                filterArrayOP = [
                  {
                  "levels": [
                    {
                      "dim": "[Ticket.Type]",
                      "datatype": "text",
                      "title": "Type",
                      "filter": {
                        "explicit": true,
                        "userMultiSelect": false,
                        "multiSelection": false,
                        "members": ["Demo"],
                      },
                    },
                    {
                      "dim": "[Ticket.Client]",
                      "datatype": "text",
                      "title": "Client",
                      "filter": {"explicit": true, "multiSelection": true, "members": tenants.map(a => a.toUpperCase())},
                    },
                  ],
                }
              ]
    }else{
      
      filterArrayBO =[
        {
          "levels": [
            {
              "dim": "[Client.Type]",
              "datatype": "text",
              "title": "Type",
              "filter": {
                "explicit": true,
                "userMultiSelect": false,
                "multiSelection": false,
                "members": ["Demo"],
              },
            },
            {
              "dim": "[Client.Client]",
              "datatype": "text",
              "title": "Client",
              "filter": {"explicit": true, "multiSelection": true, "members": nextProps.app.clients.map(a => a.toUpperCase())},
            },
          ],
        }
      ]
      filterArrayOP =[
        {
          "levels": [
            {
              "dim": "[Ticket.Type]",
              "datatype": "text",
              "title": "Type",
              "filter": {
                "explicit": true,
                "userMultiSelect": false,
                "multiSelection": false,
                "members": ["Demo"],
              },
            },
            {
              "dim": "[Ticket.Client]",
              "datatype": "text",
              "title": "Client",
              "filter": {"explicit": true, "multiSelection": true, "members": nextProps.app.clients.map(a => a.toUpperCase())},
            },
          ],
        }
      ]
    }
      this.setState({filterArrayBO, filterArrayOP});
      let dashBoard1= this.props.config.find(element => element.position == 1);//Announcements
      let dashBoard2= this.props.config.find(element => element.position == 2);//Open tickets
      let dashBoard3= this.props.config.find(element => element.position == 3);//OPERATIONS DASHBOARD
    
      if(ENVIRONMENT!=='local'){
        this.startFlow();  //Uncomment this before building - LOCAL
        // this.loadDash('opDashboard1', dashBoard1.url); 
        // this.loadDash('opDashboard2', dashBoard2.url);
        // this.loadDash('opDashboard3', dashBoard3.url);
      }
    }
 }

 startFlow = () =>{
  this.setState({loaded:true, loading:true});
  dashFlow.forEach((d, index)=>{
    this.props.currentApp.dashboards.load(d.dash).then(dash=>{
      this.setState({loading:false});
      if(index > 1){
        this.applyDbFilterOP(dash, 'mainDash');
      }else{
        this.applyDbFilterBO(dash);
      }
          d.wgt.forEach(w=>{
              const div = document.getElementById(w.id);
              div.innerHTML="";
              switch (w.id) {
                case "672b231397a46a003344f7b1": //Foundation
                  div.onclick = () => this.handleModalOperation('Foundation'); //Modal dash id 672b231bd838de003388cabf
                  break;
                case "672b231397a46a003344f7a9": //Automated (Tools & Platform)
                  div.onclick = () => this.handleModalOperation('Finance and Accounting'); //Modal dash id 672b231bd838de003388cabf
                  break;
                case "672b231397a46a003344f7a8": //Automated (RPA)
                  div.onclick = () => this.handleModalOperation('Robotic Process Automation'); //Modal dash id 672b231bd838de003388cabf
                  break;
                case "672b231397a46a003344f7b0"://Insights Driven
                  div.onclick = () => this.handleModalOperation('Analytics'); //Modal dash id 672b231bd838de003388cabf
                  break;
                case "672b231397a46a003344f7ad": //Intelligent (AI Advisors)
                  div.onclick = () => this.handleModalOperation('Artificial Intelligence'); //Modal dash id 672b231bd838de003388cabf
                  break;
                default:
                  break;
              }
              div.style.cursor = "pointer";
              const wgt = dash.widgets.get(w.id);
              wgt.container = div;
              wgt.refresh();
              const ww = this.state.preWgt;
              ww[w.id]=wgt;
              this.setState({preWgt:ww}); 
          });
      });
  });
  setTimeout(this.setRefInterval,10000);
};

setRefInterval = () =>{
  dashFlow.forEach(d=>{
      d.wgt.forEach(w=>{
          if(w.ref){
              const wgt = this.state.preWgt[w.id];
              setInterval(() => {
                  if(wgt) wgt.$$model.refresh();
              }, 1800000);  //1,800,000
          }
      })
  })
}

reloadWgt = (wid) =>{
  const div = document.getElementById(wid);
  div.innerHTML="";
  setTimeout(()=>{
      const clone = Object.assign(this.state.preWgt[wid]);
      clone.$$container = null;
      clone.refresh();
      clone.container = div;
      this.setState({disableClick:false});
  },1000);
};

handleClick = (e) =>{
  if(!this.state.disableClick){
    this.setState({active:e, showDashModal:false, show:false, disableClick:true});
    switch(e){
        case 1: this.reloadWgt("5f9ae0175fd2f53f38606de7"); break;
        case 2: this.reloadWgt("5f9ae0175fd2f53f38606de3"); break;
        case 3: this.reloadWgt("5f9ae0175fd2f53f38606de5"); break;
        case 4: this.reloadWgt("5f9ae0175fd2f53f38606de6"); break;
        default: break;
    }
  }
}

//Operation Dashboards

applyDbFilterOP =(db,dashType)=>{
  const filterOptions = {
    save: true,
    refresh: true,
    unionIfSameDimensionAndSameType: false
};
let filters = [...this.state.filterArrayOP];
if(dashType == 'dbModal'){
  db.$$model.filters.update([filters[0],this.state.filterPortfolio], filterOptions);
}else{
  if(db.id !== '5ee103515ae741084c1258a7')
  db.$$model.filters.update(filters[0], filterOptions);
}
}

loadDash = (id, url) => {
  //URL parser
  //Get dashbord IDs
  if(id!='dbModal'){
    this.setState({ showLoader: true });
    if (url) {
      let dashboardId = url.split("/");
      this.props.currentApp.dashboards
        .load(dashboardId[dashboardId.length - 1])
        .then((dash) => this.afterLoadDash(dash, id));
    }
  }else{
    //detail dashboard(widget modal)
    this.props.currentApp.dashboards
    .load(url)
    .then((dash) => this.afterLoadDash(dash, id));
  }
 

};
afterLoadDash = (db, divId) => {
  if(divId == 'dbModal'){
    this.applyDbFilterOP(db, 'dbModal');
  }else{
    this.applyDbFilterOP(db, 'mainDash');
  }
  let widgets = [];
  const layout = db.$$model.layout.columns[0].cells;
  const wRoot = document.getElementById(divId);
  wRoot.innerHTML = "";
  let i = 0;
  layout.forEach(col=>{
          i++;
          const row = this.createRow(i);
          col.subcells.forEach(cell=>{
              const width = cell.width?cell.width:100;
              row.appendChild(this.createLayout(i, cell.elements[0], width));
              widgets.push(cell.elements[0].widgetid);
          })
          wRoot.appendChild(row);
          if(i===layout.length) setTimeout(()=>this.loadWgt(db, widgets),1000);
      });
};
createRow = (i) => {
  const row = document.createElement('div');
      row.className="row";
      row.style.marginLeft="0px";
      row.style.marginRight="0px";
      row.id="row" + i;
      return row;
};
createLayout = (i,el, width) => {
  const headerDiv =document.createElement("div");
      headerDiv.id= "head" + el.widgetid;
      headerDiv.style.padding = "5px 20px";
      headerDiv.style.color = "white";
      headerDiv.style.backgroundImage ="linear-gradient(rgb(34, 145, 255), rgb(3, 48, 198))";

      const height = el.height==="" || el.height===512 ? "100%": el.height.toString().replace("px", "") + "px";
      const div = document.createElement('div');
      div.id = el.widgetid;
      div.style.height = height;
      div.style.backgroundColor="rgb(0, 0, 68)";

      const pdiv = document.createElement('div');
      pdiv.className = "layout row" + i
      pdiv.style.width= "calc(" + width +"% - 4px)";
      pdiv.style.margin="2px";
      // pdiv.style.paddingLeft="20px";
      // pdiv.style.paddingRight="20px";
      
      pdiv.appendChild(headerDiv);
      pdiv.appendChild(div);
      
      return pdiv;
};


loadWgt = (db, widgets) => {
  widgets.forEach(wgt=>{
    const div = document.getElementById(wgt)
    const oWgt = db.widgets.get(wgt);
    if(oWgt){
        const head =document.getElementById("head" + wgt);
        if(oWgt.title.length>0 && oWgt.title !== "RICHTEXT_MAIN.TITLE")
            head.innerHTML = oWgt.title;
        else
            if(head) head.style.display = "none";

        if (!oWgt.container)  
            oWgt.container = div;
        else {
            const clone = Object.assign(oWgt);
            clone.$$container = null;
            clone.container = div;
        }
        oWgt.refresh();
        const recurse = () =>{
            if(oWgt.refreshing) 
                setTimeout(recurse,1000);
            else {
              if(oWgt.id =='672b231397a46a003344f7b1' || oWgt.id =='672b231397a46a003344f7a9' || oWgt.id =='672b231397a46a003344f7a8' || oWgt.id =='672b231397a46a003344f7b0' || oWgt.id =='672b231397a46a003344f7ad'){
               switch(oWgt.id){
                case "672b231397a46a003344f7b1": //Foundation
                  div.onclick = () => this.handleModalOperation('Foundation'); //Modal dash id 672b231bd838de003388cabf
                  break;
                case "672b231397a46a003344f7a9": //Automated (Tools & Platform)
                  div.onclick = () => this.handleModalOperation('Finance and Accounting'); //Modal dash id 672b231bd838de003388cabf
                  break;
                case "672b231397a46a003344f7a8": //Automated (RPA)
                  div.onclick = () => this.handleModalOperation('Robotic Process Automation'); //Modal dash id 672b231bd838de003388cabf
                  break;
                case "672b231397a46a003344f7b0"://Insights Driven
                  div.onclick = () => this.handleModalOperation('Analytics'); //Modal dash id 672b231bd838de003388cabf
                  break;
                case "672b231397a46a003344f7ad": //Intelligent (AI Advisors)
                  div.onclick = () => this.handleModalOperation('Artificial Intelligence'); //Modal dash id 672b231bd838de003388cabf
                  break;
                default:
                  break;
               }
                div.style.cursor="pointer";
              }

                if(div.style.height === "auto") div.style.height="100%";
               
            }
        }
        recurse();
    }
    
})
this.setState({ showLoader: false });
};
handleModalOperation = (portfolio) => {
  let filterPortfolio;
  
  let dashId = '672b231bd838de003388cabf';
  this.setState({showDashModal:true, show:false, active:0,arrowShow: false})
  switch(portfolio){
    case "Foundation":
      filterPortfolio =  {"jaql": {"dim":"[Ticket.Portfolio]",
      "datatype":"text", 
      "title":"Portfolio",
      "filter":{"members":["Foundation"]}} }
      break;
      case "Finance and Accounting":
        filterPortfolio =  {"jaql": {"dim":"[Ticket.Portfolio]",
        "datatype":"text", 
        "title":"Portfolio",
        "filter":{"members":["Finance and Accounting"]}} }
        break;
      case "Robotic Process Automation":
        filterPortfolio =  {"jaql": {"dim":"[Ticket.Portfolio]",
        "datatype":"text", 
        "title":"Portfolio",
        "filter":{"members":["Robotic Process Automation"]}} }
        break;
      case "Analytics":
        filterPortfolio =  {"jaql": {"dim":"[Ticket.Portfolio]",
        "datatype":"text", 
        "title":"Portfolio",
        "filter":{"members":["Analytics"]}} }
        break;
      case "Artificial Intelligence":
        filterPortfolio =  {"jaql": {"dim":"[Ticket.Portfolio]",
        "datatype":"text", 
        "title":"Portfolio",
        "filter":{"members":["Artificial Intelligence"]}} }
        break;
     default:
        break;
  }
  this.setState({filterPortfolio})
  this.loadDash("dbModal", dashId);
}

  render() {
    
    const handleCloseOP = () => this.setState({showDashModal:false});

    let showModalOP = () =>{
      return (
            <Modal show={this.state.showDashModal} centered onHide={handleCloseOP}  className="business_modal-holder-b9" dialogClassName="modal-90w width-90-b9">
              <Modal.Header
                              closeButton={true}
                              className="dashboard-modal-header"
                            ></Modal.Header>
              <Modal.Body><div id="dbModal" className="db-container"></div>
              </Modal.Body>
            </Modal>
        );
  }
    const options = {
      height: 200,
      width: 450,
      hideTabs: false,
      // All other vizCreate options are supported here, too
      // They are listed here: https://onlinehelp.tableau.com/current/api/js_api/en-us/JavaScriptAPI/js_api_ref.htm#ref_head_9
    };
    const showArrowModalB9 = () => {
      return (
            <Modal show={this.state.arrowShow} centered size="lg" onHide={handleArrowClose} className="business_modal-holder-b9  positive-z-index">
            <Modal.Header
                closeButton={true}
                className="dashboard-modal-header"
              ></Modal.Header>
            <Modal.Body className="BO-modal"><div>
            <div id="businessModalb9" className="db-container" style={{height:"inherit"}}></div> 
          </div>
         </Modal.Body>
         </Modal>
      );
    }

        
    const handleClose = () => this.setState({show:false});
    const handleBOClose = () => this.setState({active:0});
    const handleArrowClose = () => this.setState({ arrowShow: false });
    const handleArrowModal = (drillDB) => {
      // const modImg = ModalImg.filter(f => f.imageID === drillDB)[0].image64;
      this.loadDash('businessModalb9', drillDB);
      this.setState({ arrowShow: true, showDashModal:false});
    }

    const handleModal = (drillDB) => {
      const modImg = ModalImg.filter(f=>f.imageID===drillDB)[0].image64;
        
      this.setState({show:true, image:modImg, showDashModal:false, active:0});
    }  

    return (
      <div>
        <div data-test="businessComponent">
        
        <div className={this.state.loading ? "loading" : "hidden"}>
                    <img
                        className="loader-size"
                        src={require("../../Images/WO-background.gif")}
                        alt=""
                    />
                </div>
        <section className='mt-2 py-2 px-4'>
        
        {/* {((this.props.userDetails.featureIds &&
                              this.props.userDetails.featureIds.some(
                                (val) => val == 30
                              )) || this.props.userDetails.dashboardRole == ADMIN) && (<div className="container-fluid data-container2-b9 operation-main-container tab4-background" style={{position:'relative', top:'0px'}}>          
        
                    <div className="inner-date2 text-white">        
                      <div className="container-fluid">   
                      
                              <div className="col-md-12 col-lg-12">
                              <div className="row" style={{ borderRadius:'8px', marginBottom:'5px'}} data-test='announcement-container'>
                                            <div className="dash-title-b9 col-2" style={{position:'relative', top:'1vh', paddingLeft:'0px'}}>
                                              {i18n.t((this.props.config.find(element => (element.position == 1 && element.screenSection == "T3"))).displayName)}
                                          </div>
                                            <div id="opDashboard1" className="announcements-height col-9"></div>
                                            
                                          </div>
                                    
                                      
                                
                                  </div>
                      </div>
                      </div>       
                    </div>)} */}
        
                    {((this.props.userDetails.featureIds &&
                              this.props.userDetails.featureIds.some(
                                (val) => val == 32
                              )) || this.props.userDetails.dashboardRole == ADMIN) && (<div className="container-fluid data-container2-b9 tab4-backgroundb9" style={{position:'relative',height: (this.props.app.filterObject.tenant.length==0 && (this.props.userDetails.dashboardRole == ADMIN || this.props.userDetails.tenant_Id=='All'))?'350px':'350px', top:'0px', marginTop: '20px', marginBottom: '3px'}}>          
        <div className="inner-date2 text-white" style={{marginLeft:'-1rem'}}>        
          <div className="container-fluid">   
          <div className="col-md-12 col-lg-12">
          <div className="mb-3">
                          <h5 className="dash-title-b9">{i18n.t((this.props.config.find(element => (element.position == 1 && element.screenSection == "T4"))).displayName)}</h5>
                          <div
                            data-test="businessContainer"
                            id="announcementsDashboard1"
                            className="business-height-b9"
                          >
                            <Row className={this.state.isFilter?"hidden":""}>
                              <Col md={3} style={{padding:0}}>
                                  <div id="5f9ae0175fd2f53f38606dde" onClick={()=>this.handleClick(1)}></div>
                                  <div className="anchor-b9" onClick={() => handleArrowModal("622f234eec88950036056755")}>
                                    <img 
                                      src={require("../../Images/arrowgraph.svg")}
                                    /></div>
                              </Col>
                              <Col md={3} style={{padding:0}}>
                                  <div id="5f9ae0175fd2f53f38606ddb" onClick={()=>this.handleClick(2)}></div>
                                  {(this.props.app.clients.length == 0 && (this.props.userDetails.dashboardRole == ADMIN || this.props.userDetails.tenant_Id=='All')) && (<div className="anchor-b9" onClick={() => handleArrowModal("6231ecd5fa9bff0036ab44ee")}>
                                  <img 
                                      src={require("../../Images/arrowgraph.svg")}
                                    /></div>)}
                              </Col>
                              <Col md={3} style={{padding:0}}>
                                  <div id="5f9ae0175fd2f53f38606ddc" onClick={()=>this.handleClick(3)}></div>
                                  <div className="anchor-b9" onClick={()=>handleArrowModal("6231dba0fa9bff0036ab4258")}>
                                  <img 
                                      src={require("../../Images/arrowgraph.svg")}
                                    /></div>
                              </Col>
                              <Col md={3} style={{padding:0}}>
                                  <div id="5f9ae0175fd2f53f38606ddd" onClick={()=>this.handleClick(4)}></div>
                                  <div className="anchor-b9" onClick={()=>handleArrowModal("62321ef0fa9bff0036ab4919")}>
                                  <img 
                                      src={require("../../Images/arrowgraph.svg")}
                                    /></div>
                              </Col>
                          </Row>
                          <Row className={!this.state.isFilter?"hidden":""}>
                              <Col md={3} style={{padding:0}}>
                                  <div id="5f9ae0175fd2f53f38606de2" onClick={()=>this.handleClick(1)}></div>
                                  <div className="anchor-b9" onClick={() => handleArrowModal("622f234eec88950036056755")}>
                                  <img 
                                      src={require("../../Images/arrowgraph.svg")}
                                    /></div>
                              </Col>
                              <Col md={3} style={{padding:0}}>
                                  <div id="5f9ae0175fd2f53f38606ddf" onClick={()=>this.handleClick(2)}></div>
                                  <div className="anchor-b9" onClick={()=>handleArrowModal("6231ecd5fa9bff0036ab44ee")}>
                                  <img 
                                      src={require("../../Images/arrowgraph.svg")}
                                    /></div>
                              </Col>
                              <Col md={3} style={{padding:0}}>
                                  <div id="5f9ae0175fd2f53f38606de0" onClick={()=>this.handleClick(3)}></div>
                                  <div className="anchor-b9" onClick={()=>handleArrowModal("6231dba0fa9bff0036ab4258")}>
                                  <img 
                                      src={require("../../Images/arrowgraph.svg")}
                                    /></div>
                              </Col>
                              <Col md={3} style={{padding:0}}>
                                  <div id="5f9ae0175fd2f53f38606de1" onClick={()=>this.handleClick(4)}></div>
                                  <div className="anchor-b9" onClick={()=>handleArrowModal("62321ef0fa9bff0036ab4919")}>
                                  <img 
                                      src={require("../../Images/arrowgraph.svg")}
                                    /></div>
                          </Col>
                          </Row>
                          <Modal show centered size="lg"  className={this.state.active===1?"business_modal-holder-b9  positive-z-index":"business_modal-holder-b9 negative-z-index"} onHide={handleBOClose}>
                          <Modal.Header
                              closeButton={true}
                              className="dashboard-modal-header"
                            ></Modal.Header>
                            <Modal.Body className="BO-modal b9-bo-modal">
                            <div className={this.state.active===1?"":"hidden"} id="5f9ae0175fd2f53f38606de7" style={{height:"44vh", width:"100%", padding:"20px"}}></div>
                          
                              </Modal.Body>
                          </Modal>
                          <Modal show centered size="lg"  className={this.state.active===2?"business_modal-holder-b9  positive-z-index":"business_modal-holder-b9 negative-z-index"} onHide={handleBOClose}>
                          <Modal.Header
                              closeButton={true}
                              className="dashboard-modal-header"
                            ></Modal.Header>
                            <Modal.Body className="BO-modal b9-bo-modal">
                            <div className={this.state.active===2?"":"hidden"} id="5f9ae0175fd2f53f38606de3" style={{height:"44vh", width:"100%", padding:"20px"}}></div>
                          
                              </Modal.Body>
                          </Modal>
                          <Modal show centered size="lg"  className={this.state.active===3?"business_modal-holder-b9  positive-z-index":"business_modal-holder-b9 negative-z-index"} onHide={handleBOClose}>
                          <Modal.Header
                              closeButton={true}
                              className="dashboard-modal-header"
                            ></Modal.Header>
                            <Modal.Body className="BO-modal b9-bo-modal">
                            <div className={this.state.active===3?"":"hidden"} id="5f9ae0175fd2f53f38606de5" style={{height:"44vh", width:"100%", padding:"20px"}}></div>
                          
                              </Modal.Body>
                          </Modal>
                          <Modal show centered size="lg"  className={this.state.active===4?"business_modal-holder-b9  positive-z-index":"business_modal-holder-b9 negative-z-index"} onHide={handleBOClose}>
                          <Modal.Header
                              closeButton={true}
                              className="dashboard-modal-header"
                            ></Modal.Header>
                            <Modal.Body className="BO-modal b9-bo-modal">
                            <div className={this.state.active===4?"":"hidden"} id="5f9ae0175fd2f53f38606de6" style={{height:"44vh", width:"100%", padding:"20px"}}></div>
                          
                              </Modal.Body>
                          </Modal>

                          <Modal show centered size="lg" onHide={handleClose} className={this.state.show?"business_modal-holder-b9  positive-z-index":"business_modal-holder-b9 negative-z-index"}>
                            <Modal.Header
                                            closeButton={true}
                                            className="dashboard-modal-header"
                                          ></Modal.Header>
                            <Modal.Body className="BO-modal"><div>
                                  <img src={this.state.image} alt="modal" style={{width:"100%"}}/>
                              </div>
                              </Modal.Body>
                          </Modal>
                          </div>
                        </div>
                              <div className="mb-3" style={{display:"block"}}>
                              <div className="dash-title-b9">{i18n.t((this.props.config.find(element => (element.position == 2 && element.screenSection == "T4"))).displayName)}</div>
                              <div
                                id="announcementsDashboard2"
                                className="powered-heightb9"
                              >
                                <Row>
                                  <Col><div id="5f719a0e3dad070ee862ce53"></div></Col>
                                  <Col><div id="5f719a0e3dad070ee862ce52"></div></Col>
                                  <Col><div id="5f719a0e3dad070ee862ce51"></div></Col>
                              </Row>
                              </div>
                            </div>
                    </div>
                      </div>
          </div>
          </div>)}  
        </section>
      </div>
     
      <div data-test='operations-component'>
      {((this.props.userDetails.featureIds &&
                              this.props.userDetails.featureIds.some(
                                (val) => val == 30
                              )) || this.props.userDetails.dashboardRole == ADMIN) &&
      (<div className={this.state.showLoader ? "loading" : "hidden"}>
                  <img
                      className="loader-size"
                      src={require("../../Images/WO-background.gif")}
                      alt=""
                  />
              </div>)
      }
      <section className='py-2 px-4'>
        {((this.props.userDetails.featureIds &&
                              this.props.userDetails.featureIds.some(
                                (val) => val == 30
                              )) || this.props.userDetails.dashboardRole == ADMIN) && (<div className="container-fluidb9 data-container2-b9 operation-main-container">
          <div className="row inner-date2 text-white" style={{overflowY:"hidden"}}>
            <div className="container-fluid">
              <div className="row">
                <div className="data-sections2" style={{width:'98%', paddingTop:'0px', paddingBottom:'0px',height: '55.4vh'}}>
                  <div className="row">
              
  
                              <div style={{ borderRadius: '4px', marginBottom: '8px' , width: '100% '}} data-test='open-tickets-container'>
                              {/* <div className="dash-title-b9 ml-3" style={{position:'relative', left:'-0.8rem'}}>
                                  Operations
                                  </div> */}
                                <div className="dash-title-b9 ml-3 mt-2" style={{position:'relative', left:'0rem'}}>
                                  {i18n.t((this.props.config.find(element => (element.position == 2 && element.screenSection == "T3"))).displayName)}
                                  </div>
                                <div id="opDashboard2" className="live-notifications-height" style={{marginLeft:'17px', marginTop:'15px'}}>
                                  <Row>
                                <div id="672b2317d838de003388cab2" style={{width: "calc(50% - 4px)",margin: "2px", paddingLeft: "25px", borderRadius: "10px"}}></div>
                                <div id="672b2317d838de003388cab1" style={{width: "calc(50% - 4px)",margin: "2px", borderRadius: "10px"}}></div>
                                </Row>
                                <Row>
                                  <div id="672b2317d838de003388cab4" style={{width: "calc(50% - 4px)",margin: "2px", paddingLeft: "25px", borderRadius: "10px"}}></div>
                                  <div id="672b2317d838de003388cab3" style={{width: "calc(50% - 4px)",margin: "2px", borderRadius: "10px"}}></div>
                                </Row>
                                </div>
                              
                              </div>
                          </div>
                              <div  className="row">
                          <div style={{width: '100%'}}>
                      <div className="dash-title-b9 ml-3" style={{position:'relative', left:'0rem'}}>
                      {i18n.t((this.props.config.find(element => (element.position == 3 && element.screenSection == "T3"))).displayName)}​​
                      {/* Tableau Dashboard */}
                      </div>
                      <div
                        id="opDashboard3"
                        className="operations-height"
                        style={{marginLeft:'35px', marginTop:'20px', overflowX: "hidden"}}
                      >
                        <Row>
                          <div id="672b231397a46a003344f7b1" style={{width: "calc(20% - 4px)", margin: "2px", borderRadius: "9px"}}></div>
                          <div id="672b231397a46a003344f7a9" style={{width: "calc(20% - 4px)", margin: "2px", borderRadius: "9px"}}></div>
                          <div id="672b231397a46a003344f7a8" style={{width: "calc(20% - 4px)", margin: "2px", borderRadius: "9px"}}></div>
                          <div id="672b231397a46a003344f7b0" style={{width: "calc(20% - 4px)", margin: "2px", borderRadius: "9px"}}></div>
                          <div id="672b231397a46a003344f7ad" style={{width: "calc(20% - 4px)", margin: "2px", borderRadius: "9px"}}></div>
                        </Row>
                        <Row>
                          <div id="672b231397a46a003344f7af" style={{width: "calc(20% - 4px)", margin: "2px", borderRadius: "9px", height: "308px"}}></div>
                          <div id="672b231397a46a003344f7b2" style={{width: "calc(20% - 4px)", margin: "2px", borderRadius: "9px", height: "308px"}}></div>
                          <div id="672b231397a46a003344f7aa" style={{width: "calc(20% - 4px)", margin: "2px", borderRadius: "9px", height: "308px"}}></div>
                          <div id="672b231397a46a003344f7ac" style={{width: "calc(20% - 4px)", margin: "2px", borderRadius: "9px", height: "308px"}}></div>
                          <div id="672b231397a46a003344f7ae" style={{width: "calc(20% - 4px)", margin: "2px", borderRadius: "9px", height: "308px"}}></div>
                        </Row>
                        <Row style={{float:"right"}}>
                          <div id="672b231397a46a003344f7ab" style={{ margin: "2px", borderRadius: "9px"}}></div>
                        </Row>
                       </div>
                    </div> </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>)}
      </section>
      {showModalOP()}
      {showArrowModalB9()}
    </div>
       </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
      app: state.appReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
      toggleMenuDisplay: (boolean) => {
          dispatch(toggleMenuDisplay(boolean));
      },
      setFilters: (obj) => {
          dispatch(setFilters(obj));
      },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(B9DataSection);
